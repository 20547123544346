//#######################################################################################
//#                                 CONTACT MODAL                                       #
//#######################################################################################
export function getContactModal() {
    $('.ui.contact.modal').remove();
    Rails.ajax({
        url: '/usr/system_emails/new.json',
        type: 'GET',
        dataType: 'JSON',
        data: new URLSearchParams({mail_type: 'app_support'}).toString(),
        success: function (data) {
            $('body').append(data.contact);
            $('.ui.contact.modal').modal('show');
            $('.ui.dropdown').dropdown();
        }
    });
}

export function getComplaintModal() {
    $('.ui.complaint.modal').remove();
    Rails.ajax({
        url: '/usr/system_emails/new.json',
        type: 'GET',
        dataType: 'JSON',
        data: new URLSearchParams({mail_type: 'complaint'}).toString(),
        success: function (data) {
            $('body').append(data.contact);
            $('.ui.complaint.modal').modal('show');
        }
    });
}