//#######################################################################################
//#                                 NOTIFICATIONS                                       #
//#######################################################################################

export function updateNotificationsCount(count) {
    $('.unread_count').text(count)
    if (count == 0) {
        $('.notifications_icon').attr('data-tooltip', "Vous n'avez pas de notification").attr('data-inverted', "").attr('data-position', "bottom center");
    } else if (count == 1) {
        $('.notifications_modal_mobile').html('<i class="bell red icon"></i>Vous avez <span class="ui unread_count">' + count + '</span>' + ' nouvelle notification' + ' !');
    }
}

export function getNewNotifications() {
    Rails.ajax({
        url: '/notifications.json',
        type: 'GET',
        dataType: 'JSON',
        success: function (data) {
            updateNotificationsCount(data.length);
            $('.notification-item').remove();
            $.each(data, function (key, value) {
                $('.notification-items').append(value.template);
            });
            if (data.length > 0) {
                $('.ui.fullscreen.notifications.modal').modal({allowMultiple: true}).modal('show');
            };
        }
    });
}

export function removeNotification(id) {
    var notification_read_icon = $('#' + id)
    Rails.ajax({
        url: '/notifications/mark_as_read',
        type: 'POST',
        dataType: 'JSON',
        data: new URLSearchParams({id: id}).toString(),
        success: function (data) {
            updateNotificationsCount(data.unread_count);
            if (data.check == true) {
                if (data.unread_count == 0) {
                    $(notification_read_icon).transition('fade out').removeClass('grey outline eye ').addClass('green check').transition('fade in').transition('tada', 1000, function() {
                        $(notification_read_icon).closest('.notification-item').remove();
                        $('.notifications_modal_mobile').remove();
                        $('.ui.fullscreen.notifications.modal').modal('hide');
                    });
                } else {
                    $(notification_read_icon).transition('fade out').removeClass('grey outline eye ').addClass('green check').transition('fade in').transition('tada', 1000, function() {
                        $(notification_read_icon).closest('.notification-item').remove();
                        updateNotificationsCount(data.unread_count);
                    });
                }
            };
        }
    });
}

export function removeAllNotifications() {
    Rails.ajax({
        url: '/notifications/mark_all_as_read',
        type: 'POST',
        dataType: 'JSON',
        success: function (data) {
            $('.eye').transition('fade out').removeClass('grey outline eye ').addClass('green check').transition('fade in').transition('tada', 1000, function() {
                $('.notification-item').remove();
                $('.ui.fullscreen.notifications.modal').modal('hide');
                $('.notifications_modal_mobile').remove();
                updateNotificationsCount(data.unread_count);
            });
        }
    });
}

export function removeAllNotificationsConfirm() {
    $('.ui.mini.remove_all_notifications.modal')
        .modal({allowMultiple: true})
        .modal('attach events', '.ui.fullscreen.notifications.modal .button')
        .modal('show');
}

export function transferConfirm(valid) {
    $.ajax({
        url: '/usr/users/transfer_confirm',
        type: 'POST',
        dataType: 'JSON',
        data: new URLSearchParams({valid: valid}).toString(),
        success: function (data) {
            window.location.reload();
        }
    });
}