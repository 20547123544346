//#######################################################################################
//#                              CONFIDENTIALITE MODAL                                  #
//#######################################################################################

export function getPrivacyModal(first = false) {
    $('.ui.privacy-agreement.modal').remove();
    Rails.ajax({
        url: '/usr/users/privacy.json',
        type: 'GET',
        dataType: 'JSON',
        success: function (data) {
            $('body').append(data.html);
            var opt = first ? {closable: false} : null
            $('.ui.privacy-agreement.modal').modal(opt).modal('show');
        }
    });
}