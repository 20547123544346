/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Vitepatoche')

import jQuery from "jquery";
import $ from 'jquery';
window.$ = $;
window.jQuery = jQuery();
// import jQuery from "jquery";
//
// // define & and jQuery on the global window object
// Object.assign(window, { $: jQuery, jQuery });

// IMPORT LIBRAIRIES //
import "@rails/ujs" // Autostarts
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
import * as Turbolinks from "turbolinks"
Turbolinks.start()
import "../channels"


// IMPORT CSS //
import '../dist/semantic.css'
import '../stylesheets/main.css'
import '../stylesheets/responsive.css'
import '../stylesheets/datatable.css'
import '../stylesheets/datatable_custom.css'
import '../stylesheets/datatable_plugins.css'
import '../stylesheets/loading-bar.css'



import 'datatables.net-dt';
import 'datatables.net-responsive-dt'
import 'datatables.net-colreorder-dt'

// IMPORT JS //
import '../dist/semantic'
import '../js/custom_anonymous'
import '../js/confirm_modal'
import '../js/loading-bar'

import * as contact from '../js/contact'
window.contact = contact

import * as notifications from '../js/notifications'
window.notifications = notifications

import * as privacy from '../js/privacy'
window.privacy = privacy

import * as scanqr from '../js/scanqr'
window.scanqr = scanqr

import * as datatable from '../js/datatable'
window.datatable = datatable

import * as custom from '../js/custom'
window.custom = custom

