//#######################################################################################
//#                                     SCAN QR                                         #
//#######################################################################################

import { BrowserQRCodeReader } from '@zxing/library/es2015';

export function getQRModal() {
    $('.ui.equipment-qrscan.modal').remove();
    $('.ui.dimmer.modals').remove();
    Rails.ajax({
        url: '/usr/qrscan/qr_scan.json',
        type: 'GET',
        dataType: 'JSON',
        success: function (data) {
            $('body').append(data.html);
            $('.equipment-qrscan').modal('show');
        }
    });
}

export function getQRActions(result, type = "usr") {
    $('.qr-equipment-info').html('');
    $('.qr-equipment-img').html('');
    $('.qr-equipment-message').html('').hide();
    Rails.ajax({
        url: '/'+ type +'/qrscan/get_actions.json',
        type: 'GET',
        dataType: 'JSON',
        data: new URLSearchParams({qr_code: result}).toString(),
        success: function (data) {
            if(data.error === true) {
                $('.qr-equipment-message').html(data.message).show();
            } else {
              if(type === "nda") {
                  window.location.href = data.equipment_path
              } else {
                  $('.qr-equipment-info').html(data.html_info);
                  $('.qr-equipment-img').html(data.html_img);
              }
            }
        }
    });
}

export function getQRAssociation(c, id, namespace) {

    $('.qr-associate-scan').show();
    $('.qr-message').html('');
    $('#'+ c +'_qr_code').val(null);

    const codeReader = new BrowserQRCodeReader();
    const videoId = codeReader.listVideoInputDevices()[0];
    codeReader
        .decodeOnceFromVideoDevice(videoId, 'video')
        .then(
            result =>
                (
                    $.ajax({
                        url: '/'+ namespace +'/qrscan/qr_associate_check',
                        type: 'GET',
                        dataType: 'JSON',
                        data: { qr_code: result.text, class_name: c, id: id },
                        success: function (data) {
                            $('.qr-associate-scan').hide();
                            codeReader.stopStreams();
                            if(data.valid === false) {
                                $('.qr-message').html('<span class="ui red text">'+ data.message +'</span>');
                            } else {
                                $('.qr-message').html('<span class="ui green text">'+ data.message +'</span>');
                                $('#'+ c +'_qr_code').val(result.text);
                            }
                        }
                    })
                )
        )
        .catch(err => null);

    $(document).on('turbolinks:before-cache', function() {
        codeReader.stopStreams();
    });
}

/*
##############################################################
#                       DISTRIBUTION                         #
############################################################## */

export function getQRDistribution(qr_code, type) {

    var user_id = (type === 'equipment') ? $('#user_id').val() : '' ;

    $.ajax({
        url: '/usr/qrscan/qr_distribution',
        method: 'POST',
        dataType: 'JSON',
        data: new URLSearchParams({ qr_code: qr_code, type: type, user_id: user_id }).toString(),
        success: function(data) {
            if(data.error === true) {
                $('.error-message-' + type).show().transition('flash');
                $('.error-message-' + type + '-content').html(data.html);
                $('.rescan-button-' + type).show();
                $('#video-' + type).hide();
            } else {
                if(type === 'user') {
                    $('.distrib-content').html(data.html);
                } else if(type === 'equipment') {
                    var namecheck = 'distribution_' + data.classname + '_' + data.id;
                    if($('#' + namecheck).length > 0)
                    {
                        $('.error-message-' + type).show().transition('flash');
                        $('.error-message-' + type + '-content').html('Équipement déjà scanné');
                        $('.rescan-button-' + type).show();
                        $('#video-' + type).hide();
                    } else {
                        $('.equipment-distribution-list').append(data.html).transition('fade up').transition('fade up');
                        $('#video-' + type).hide();
                        $('.rescan-button-' + type).show();
                        $('.distribution-valid-button').removeClass('disabled');
                    }
                }
            }
        }
    });
}

export function getQRDistributionGetEquip(user_id) {
    $.ajax({
        url: '/usr/qrscan/qr_distribution_get_equip',
        method: 'POST',
        dataType: 'JSON',
        data: new URLSearchParams({ user_id: user_id }).toString(),
        success: function(data) {
            $('.distrib-content').html(data.html);
        }
    });
}

export function validateQRDistributionEquip(equipItems, user_id){

    $.ajax({
        url: '/usr/qrscan/qr_distribution_validation',
        method: 'POST',
        dataType: 'JSON',
        data: new URLSearchParams({ equip_items: equipItems, user_id: user_id }).toString(),
        success: function(data) {
            $('.distrib-content').html(data.html);
        }
    });
}