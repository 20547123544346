$(document).on('turbolinks:load', function() {

    //######################################################################################
    //#                                  DATATABLE                                         #
    //######################################################################################

    document.addEventListener("turbolinks:before-cache", function () {
        if ($('[id^=DataTables_Table]').length == 0) {
            $('.dataTable').DataTable().destroy();
        }
    });

    //######################################################################################
    //#                                     FILTERS                                        #
    //######################################################################################

    $('.filter-opt').on('click', function () {

        $('.filter-opt').each(function(){ $(this).removeClass('active') });
        $(this).toggleClass('active');

        $.ajax({
            url: window.location.pathname,
            type: 'GET',
            dataType: 'JSON',
            data: { filter: $(this).data('filter') },
            success: function(table) {
                $('.table-render').html(table.tab_render);
                datatable.setDataTable(table.tab_id);
                if (table.options !== undefined) {
                    datatable.setFilterAjaxReturnOptions(table);
                }
                $('.ui.dropdown').dropdown();
            }
        });

    });

    //######################################################################################
    //#                                     MESSAGES                                       #
    //######################################################################################

    $(function () {
        $('.alerts').on('click', function () {
            $(this).closest('.alerts').transition('slide down');
        });
    });

    //######################################################################################
    //#                                     FORM-MESSAGES                                  #
    //######################################################################################

    $(function () {
        $("#user_owned").on('change', function () {

            var state = $('#user_owned').is(":checked") ? true : false;

            $('*[id*=local_ref]').each(function() {

                var prefix = $(this).prop('id').split('_')[0];

                if (state) {
                    $('.user-owned-message').removeClass('hidden');
                    $(this).parent().addClass('disabled');
                    $('.ui.form').form('remove rule', prefix + '_local_ref', 'empty');
                }
                else {
                    $('.user-owned-message').addClass('hidden');
                    $(this).parent().removeClass('disabled');
                    $('.ui.form').form('add rule', prefix + '_local_ref', { rules: [{ type: 'empty', prompt: '<strong>Réf. locale</strong> : Ce champ est obligatoire'}]});
                }

            });

        });
    });

    // Admin select owner
    $(function () {

        $(".admin-owner-choice :radio").click(function(){

            let prefix = $(this).prop('id').split('_')[0];

            let equip_uid = $('#' + prefix + '_user_id'), equip_auto_uid = $('#auto_user_id');
            let equip_cid = $('#' + prefix + '_customer_id'), equip_auto_cid = $('#auto_customer_id');
            let equip_lrf = $('#' + prefix + '_local_ref');
            let regime = $('#regime'), regime_field = $('.regime-field');

            if($(this).attr('id') === prefix + '_owner_club'){
                equip_cid.attr("disabled", false);
                equip_uid.attr("disabled", true);
                equip_uid.val('');
                equip_auto_uid.val('');
                regime_field.hide();

                $('*[id*=local_ref]').each(function() {
                    equip_lrf.attr("disabled", false);
                    $('.ui.form').form('add rule', prefix + '_local_ref', { rules: [{ type: 'empty', prompt: '<strong>Réf. locale</strong> : Ce champ est obligatoire'}]});
                });

            } else if($(this).attr('id') === prefix + '_owner_member'){
                equip_cid.attr("disabled", true);
                equip_uid.attr("disabled", false);
                equip_cid.val('');
                equip_auto_cid.val('');
                regime_field.show();

                $('*[id*=local_ref]').each(function() {
                    equip_lrf.val('');
                    equip_lrf.attr("disabled", true);
                    $('.ui.form').form('remove rule', prefix + '_local_ref', 'empty');
                });

            }

        });
    });



    //#######################################################################################
    //#                                        DOMS                                         #
    //#######################################################################################

    $('.auto-input').on('change', function () {
        if(this.value.length == 0) { $(this).parent().find('.hdn-auto').val(''); }
    });

    // ACTIVATION DES DROPDOWNS
    $(function () {
        $('.ui.dropdown').dropdown({
                ignoreDiacritics: true,
                fullTextSearch: 'exact',
                message: {
                    noResults : 'Aucun résultat'
                }
        });
    });

    // PERMET DE FIXER BUGS BOUTON PREVIOUS AVEC TURBOLINKS SUR CERTAINS DROPDOWNS
    $(document).on('turbolinks:before-cache', function() {
        $('.ui.dropdown').dropdown({action: 'hide'});
    });

    //#######################################################################################
    //#                                 TOGGLE EQUIPMENTS                                   #
    //#######################################################################################

    $(function () {
        $(".toggle-trigger").click(function (event) {
            if (!$(event.target).closest('.toggle-button').length) {     // Removes toggle-button from click event
                $(this).siblings(".toggle-content").toggle("slow");
                $(this).find(".toggle-button").transition('fade');
                $(this).find(".toggle-icon").toggleClass('up down');
            }
        });
    });


    //#######################################################################################
    //#                                     HAMBURGER                                       #
    //#######################################################################################

    $(function () {
        $('.hamburger').click(function (e) {
            let $menu = $(this).parent();
            if (!$(this).hasClass('active')) {
                $(this).addClass('active');
                $menu.addClass('open');
            } else {
                $(this).removeClass('active');
                $menu.removeClass('open');
            }
            e.preventDefault();
        });
    });

});

//#######################################################################################
//#                                   LOADING BAR                                       #
//#######################################################################################

$(document).on('turbolinks:load', function() {

    $(function() {
        $('.ldBar').each(function(idx) {
            var bar = new ldBar('#' + $(this).attr('id'));
            bar.set($(this).data('progress'));
        });
    });

});

$(document).on('turbolinks:before-cache', function() {
    $('.ldBar').empty();
});

//#######################################################################################
//#                                   CONTROLS FORMS                                    #
//#######################################################################################

$(document).on('turbolinks:load', function() {

    $('.control-button-check').click(function(){

        var code = $(this).data('code');
        var subcode = $(this).data('subcode');

        if (subcode) {
            $('.control-button-check').each(function(){
                ($(this).data('code') === code && $(this).data('subcode') === subcode ) ? $(this).removeClass('red green black') : null
            });
        } else {
            $('.control-button-check').each(function(){
                $(this).data('code') === code ? $(this).removeClass('red green black') : null
            });
        }

        switch($(this).data('checked')){
            case 'na':
                $(this).toggleClass('black');
                break;
            case 'no':
                $(this).toggleClass('red');
                break;
            case 'yes':
                $(this).toggleClass('green');
                break;
        }
        if (subcode) {
            $('#summary_' + subcode + '_' + code).val($(this).data('checked'));
        } else {
            $('#summary_' + code).val($(this).data('checked'));
        }


    });

    $('.check-validation').click(function(){

        $('.check-validation').each(function(){ $(this).removeClass('red green'); });

        switch($(this).data('checked')){
            case false:
                $(this).toggleClass('red');
                break;
            case true:
                $(this).toggleClass('green');
                break;
        }

        $('#control_validation').val($(this).data('checked'));

        $('#inspection_validation').val($(this).data('checked'));

    });

});