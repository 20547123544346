export function setAutoComplete(name, source){

    var content = [];
    $.each(source, function(val, key) { content.push({title: val, id: key}); });

    $('.ui.search')
        .search({
            source: content,
            error: {
                noResultsHeader: 'Aucun résultat',
                noResults : 'Pas de résultat correspondant' },
            onSelect : function(result){ $('#auto_'+name).val(result.id); }
        });
}

export function deleteEquipment(to_display) {
    if (typeof to_display !== 'undefined') {
        $('#deletion').append(to_display);
        $('#delete-button').addClass('disabled');
        $('.ui.deletion.modal').modal('show');
        $('.ui.dropdown').dropdown();
    } else {
        $('#deletion-content').remove();
        $('#delete-button').removeClass('disabled');
    }
}

export function setCalendar(time = false) {

    var current_calendar = $('.ui.calendar');

    let caltype = time ? 'datetime' : 'date';

    current_calendar.ready(function(){
        current_calendar
            .calendar({
                type: caltype,
                monthFirst: false,
                text: {
                    days: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
                    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
                    monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
                    today: 'Aujourd\'hui',
                    now: 'Maintenant',
                    am: 'AM',
                    pm: 'PM'
                },
                formatter: {
                    /*date: function(date, settings) {
                        if (!date) return '';
                        var day = (date.getDate().toString().length == 2) ? date.getDate() : '0' + date.getDate();
                        var month = ((date.getMonth()+1).toString().length == 2) ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
                        var year = date.getFullYear();
                        return day + '/' + month + '/' + year;
                    }*/
                    date: 'DD/MM/Y',
                    datetime: 'DD/MM/Y HH:mm'
                }
            });
    });

}

export function setUpload(identity) {
    var input = $('#'+identity); var id = input.attr('id');
    var file = input.val().replace(/\\/g, '/').replace(/.*\//, '');
    var size = 0; var display_size; var display;

    $.each(input.get(0).files, function (i, v) { size += v.size });
    display_size = size < 1000000 ? Math.round(size / 1000) + ' Ko' : Math.round(size / 1000000) + ' Mo';
    display = input.get(0).files.length > 1 ? input.get(0).files.length + ' Fichiers' : file;
    display += ' (' + display_size + ')';
    $('#' + id + '_file').val(display);
    $('#' + id + '_size').val(size)
}

export function getCheckupModal() {
    $('.ui.checkup.modal').remove();
    Rails.ajax({
        url: '/tks/controls/new.json',
        type: 'GET',
        dataType: 'JSON',
        data: new URLSearchParams({checkup: 'true'}).toString(),
        success: function (data) {
            $('body').append(data.checkup);
            $('.ui.checkup.modal').modal('show');
            $('.ui.dropdown').dropdown();
        }
    });
}

export function addCustomHistory(params) {
    var type = params.data('type');
    var id = params.data('id');
    var message = $('#history_custom_comment').val();

    $.ajax({
        url: '/usr/equipments/add_custom_history',
        method: 'POST',
        dataType: 'JSON',
        data: new URLSearchParams({equipment_type: type, equipment_id: id, message: message}).toString(),
        success: function(data) {
            if(data.success === false) {
                $('.custom-history-error').html('<span class="ui red text">' + data.message + '</span>').show();
            } else {
                window.location.reload(true);
            }
        }
    });

}

export function tutorSignModalButton(control_id, klass) {
    let button     = $("#tutor-sign-button").attr("onClick");
    let methodname = button.substring(0,button.indexOf("("));
    $("#tutor-sign-button").attr("onclick",methodname + "(" + control_id + ",'" + klass+ "')");
    $("#autonomy_check").prop("checked", false);
    $("#autonomy_comment").val('');
    $('.ui.tutor-sign-modal.modal').modal('show');
}

export function tutorSignButton(control_id, klass) {

    let autonomy_check   = $("#autonomy_check").is(':checked');
    let autonomy_comment = $("#autonomy_comment").val();

    Rails.ajax({
        url: '/tks/controls/tutor_sign.json',
        type: 'POST',
        dataType: 'JSON',
        data: new URLSearchParams({id: control_id, tutor_signed: 'true', autonomy_check: autonomy_check, autonomy_comment: autonomy_comment}).toString(),
        success: function (data) {
            $('.ui.tutor-sign-modal.modal').modal('hide');
            setTimeout(function(){
                let button  = $('.tutor-sign-modal-button-' + klass.toLowerCase() + '-' + control_id)
                let segment = $('.tutor-sign-segment')
                if (data["signed"] == true) {
                    button.removeClass('red').addClass('green')
                    button.html('<i class="ui check icon"></i>SIGNÉ')
                }
                if (data["signs_completed"] == true) {
                    $('.tutor-sign-message').hide()
                    $('body').toast({class: 'green',message: 'Tout est à jour !'});
                    setTimeout(function(){
                        segment.fadeOut();
                    }, 3000);
                }
            }, 1000);
        }
    });
}

export function ownerSignButton(control_id, klass) {
    Rails.ajax({
        url: '/usr/owner_sign.json',
        type: 'GET',
        dataType: 'JSON',
        data: new URLSearchParams({id: control_id, owner_signed: 'true', klass: klass}).toString(),
        success: function (data) {
            let button  = $('.owner-sign-button-' + klass.toLowerCase() + '-' + control_id)
            let segment = $('.owner-sign-segment')
            let unsigned_control_count = $('.unsigned_control_count')
            let unsigned_control_count_button = $('.owner_sign_link').children('.button')
            if (data["signed"] == true) {
                button.removeClass('red').addClass('green')
                button.html('<i class="ui check icon"></i>SIGNÉ')
                unsigned_control_count.html(data['unsigned_count'])
            }
            if (data["signs_completed"] == true) {
                $('.owner-sign-message').removeClass('red').addClass('green')
                $('.owner-sign-header-text').html('Procès verbaux à jour')
                $('.owner-sign-text').html('Toutes les requalifications et/ou inspections invalides ont été signées')
                unsigned_control_count_button.addClass('disabled')
                $('[class*="owner-sign-button-"]').addClass('disabled')
                $('.owner_sign_link_mobile').remove()
                $('body').toast({class: 'green',message: 'Tout est à jour !'});
            }
        }
    });
}

// PERIPHRAL REMOVAL IN REGULATOR FORMS
export function removePeripheral(peripheral_id, namespace) {
    $.ajax({
        url: "/" + namespace + "/regulators/remove_peripheral.json",
        data: {peripheral_id: peripheral_id},
        success: function(data) {
            $('#per_'+peripheral_id).remove();
            if (peripheral_id > 0) $('#peripherals').append(new Option(data.display_name, data.id));
            if($("[id^=per_]").length === 0) {
                $(".no-peripherals").show();
            }
        }
    });
}