//######################################################################################
//#                                  DATATABLE                                         #
//######################################################################################

export function setDataTable(table, basic = false){

    let responsive_mode;

    let responsive = false;
    let paging = true;
    let ordering = true;
    let searching = true;
    let colreorder = true;
    let info = true;

    if(basic == 'true') {
        paging = false;
        ordering = false;
        colreorder = false;
        searching = false;
        info = false;
    }

    $.extend($.fn.dataTableExt.oStdClasses, {
        "sLengthSelect": "ui dropdown",
        "sPageButton": "ui button",
        "sPageButtonActive": "primary button"
    });

    if (window.matchMedia('screen and (max-width: 768px)').matches) {
        responsive_mode = 'mobile';
    } else if (window.matchMedia('screen and (min-width: 992px)')) {
        responsive_mode = 'computer';
    }

    if (responsive_mode != 'computer') {
        responsive = true;
        colreorder = false;
    }

    if ($('#'+table+'_wrapper').length == 0)
    {
        var dtInstance = $('#'+table).DataTable({
            bDestroy: true,
            stateSave: true,
            responsive: responsive,
            colReorder: colreorder,
            paging: paging,
            searching: searching,
            aLengthMenu: [
                [10, 20, 75, 100, -1],
                [10, 20, 75, 100, "Tout"],
            ],
            ordering: ordering,
            columnDefs: [
                { orderable: false, targets: "dt-no-sort" },
                { width: "2%", targets: "select-all-checkbox" },
                {targets: "dt-truncate"}
            ],
            createdRow: function(row){
                $(row).find(".dt-truncate").each(function(){
                    $(this).attr("title", this.innerText);
                });},
            info: info,
            language: {
                emptyTable: "Pas d'entrées dans cette liste",
                search: "",
                searchPlaceholder: "Recherche...",
                infoFiltered: "(filtré sur un total de _MAX_ entrées)",
                zeroRecords : "Aucun résultat",
                lengthMenu: "_MENU_",
                paginate: {
                    first: "Première",
                    last: "Dernière",
                    next: "<i class='angle right icon'></i>",
                    previous: "<i class='angle left icon'></i>"
                },
                info: "_START_ à _END_ sur _TOTAL_",
                infoEmpty: "",
                loading: "Récupération...",
                processing: "Analyse...",
                aria: {
                    sortAscending:  ": activer pour trier la colonne par ordre croissant",
                    sortDescending: ": activer pour trier la colonne par ordre décroissant"
                }
            },
            initComplete: function(settings, json) {
                $('#'+table).show();
            },
        });

        // if (responsive_mode != 'computer') {
        //     $('#'+table).addClass('compact');
        //     dtInstance.columns.adjust();
        //     dtInstance.responsive.recalc();
        // }

        return dtInstance;
    }
}

export function setFilterAjaxReturnOptions(table){
    if (table.options == 'withdrawals') {
        $('.button-render').html(table.button_render);
        $(".button-render").show();
        let table_content = $('#' + table.tab_id).DataTable().rows().nodes();
        $('#' + table.tab_id).on('change', 'thead input[type="checkbox"]', function(){
            table_content.$('input[type="checkbox"]').prop('checked', this.checked);
        })
    }
}